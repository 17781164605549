import styles from './Input.module.css'
import { IMaskInput } from "react-imask";

const Input = ({ type, placeholder, value, onChange, telefone }) => {
  return (
    <>
      {telefone ?
        <IMaskInput
          value={value}
          onChange={e => onChange(e.target.value)}
          mask="(00) 0 0000-0000"
          placeholder={placeholder}
        />
        :
        <input
          type={type}
          placeholder={placeholder}
          value={value}
          onChange={e => onChange(e.target.value)}
        />
      }
    </>
  )
}

export default Input