import { BsWhatsapp } from 'react-icons/bs'
import './Floating.css'
import ButtonMenu from '../Button'

const Floating = (props) => {
  return (
    <>
      {
        props.whatsapp ?
          <div className='floating'>
            <a className='btn-wpp' href="https://wa.me/5571992402722?text=Olá!%20Quero%20mais%20informações,%20por%20favor.%20" target="_blank"><BsWhatsapp /></a>
          </div>
          :
          <div className='floating-simule'>
            <div className="pop-up">
              Simule seu plano aqui.
              <ButtonMenu color='#F5821F' fontWeight='700'>Simular</ButtonMenu>
            </div>
          </div>

      }
    </>
  )
}

export default Floating