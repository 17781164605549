import styles from './Slide2.module.css'
import { register } from 'swiper/element/bundle'
import ButtonMenu from '../Button'
import { useState, useEffect } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import 'swiper/css/autoplay';
register();


const Slide = (props) => {
  const cards = [
    {
      city: 'Alagoas',
      name: 'Hospital Maceió',
      street: 'Av. Getúlio Vargas 300 Serraria - Maceió',
      image: '/images/hp-maceio.jpg'
    },
    {
      city: 'Santa Catarina',
      name: 'Hospital Geral Joinville',
      street: 'Rua Itaiópolis, Nº128 - Bairro América - Joinville - SC ',
      image: '/images/hp-joinvile.png'
    },
    {
      city: 'Ceará',
      name: 'Hospital Antonio Prudente',
      street: 'Av. Aquanambi nº1827 Fátima, Fortaleza - CE ',
      image: '/images/hp-antonio-prudente.jpg'
    },
    {
      city: 'Rio Grande do Norte',
      name: 'Hospital Antonio Prudente',
      street: 'Av. Presidente Quaresma 930 - Bairro Alecrim, Natal - RN ',
      image: '/images/hp-natal.png'
    },
    {
      city: 'Sergipe',
      name: 'Hospital Gabriel Soares',
      street: 'Rua Itabaiana, 690, Centro, Aracaju – SE ',
      image: '/images/hp-gabriel-soares.jpg'
    },
    {
      city: 'Alagoas',
      name: 'Hospital Maceió',
      street: 'Av. Getúlio Vargas 300 Serraria - Maceió',
      image: '/images/hp-maceio.jpg'
    },
    {
      city: 'Santa Catarina',
      name: 'Hospital Geral Joinville',
      street: 'Rua Itaiópolis, Nº128 - Bairro América - Joinville - SC ',
      image: '/images/hp-joinvile.png'
    },
    {
      city: 'Ceará',
      name: 'Hospital Antonio Prudente',
      street: 'Av. Aquanambi nº1827 Fátima, Fortaleza - CE ',
      image: '/images/hp-antonio-prudente.jpg'
    },
    {
      city: 'Rio Grande do Norte',
      name: 'Hospital Antonio Prudente',
      street: 'Av. Presidente Quaresma 930 - Bairro Alecrim, Natal - RN ',
      image: '/images/hp-natal.png'
    },
    {
      city: 'Sergipe',
      name: 'Hospital Gabriel Soares',
      street: 'Rua Itabaiana, 690, Centro, Aracaju – SE ',
      image: '/images/hp-gabriel-soares.jpg'
    },
    {
      city: 'Alagoas',
      name: 'Hospital Maceió',
      street: 'Av. Getúlio Vargas 300 Serraria - Maceió',
      image: '/images/hp-maceio.jpg'
    },
    {
      city: 'Santa Catarina',
      name: 'Hospital Geral Joinville',
      street: 'Rua Itaiópolis, Nº128 - Bairro América - Joinville - SC ',
      image: '/images/hp-joinvile.png'
    },
    {
      city: 'Ceará',
      name: 'Hospital Antonio Prudente',
      street: 'Av. Aquanambi nº1827 Fátima, Fortaleza - CE ',
      image: '/images/hp-antonio-prudente.jpg'
    },
    {
      city: 'Rio Grande do Norte',
      name: 'Hospital Antonio Prudente',
      street: 'Av. Presidente Quaresma 930 - Bairro Alecrim, Natal - RN ',
      image: '/images/hp-natal.png'
    },
    {
      city: 'Sergipe',
      name: 'Hospital Gabriel Soares',
      street: 'Rua Itabaiana, 690, Centro, Aracaju – SE ',
      image: '/images/hp-gabriel-soares.jpg'
    },
  ]
  const [activeIndex, setActiveIndex] = useState(0);
  const [isSmallScreen, setIsSmallScreen] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth <= 732);
    };

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <Swiper
      slidesPerView={1}
      navigation={true}
      autoplay={true}
      loop={true}
      onSlideChange={(swiper) =>
        setActiveIndex(isSmallScreen ? swiper.activeIndex : swiper.activeIndex + 1)
      }
      breakpoints={{
        732: {
          slidesPerView: 3,
        },
      }}
    >
      {cards.map((card, index) => (
        <SwiperSlide key={index}>
          <div
            className={`${styles.containerCard} ${index === activeIndex ? styles.active : ''
              }`}
          >
            <img src={card.image} alt="" />
            <h4 className={styles.city}>{card.city}</h4>
            <h3 className={styles.name}>{card.name}</h3>
            <p className={styles.street}>{card.street}</p>
          </div>
        </SwiperSlide>
      ))}
    </Swiper>
  );
}

export default Slide