import Input from '../Input'
import { useState } from 'react'
import ButtonMenu from '../Button'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios'

const Form = (props) => {
  const notifyError = () => toast.error('Preencha todos os campos!', {
    position: "top-left",
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "colored",
  });
  const notifySuccess = () => toast.success('Cadastrado com sucesso!', {
    position: "top-left",
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "colored",
  });
  const [isLoading, setIsLoading] = useState(false)
  const [isName, setIsName] = useState('')
  const [isEmail, setIsEmail] = useState('')
  const [isTelefone, setIsTelefone] = useState('')
  const [isCity, setIsCity] = useState('')
  // const citys = [
  //   { name: 'Salvador', year: 1994 },
  //   { name: 'Goiânia', year: 1994 },
  //   { name: 'Recife', year: 1994 },
  //   { name: 'Fortaleza', year: 1994 },
  //   { name: 'Feira de Santana', year: 1994 },
  //   { name: 'Belém', year: 1994 },
  //   { name: 'Anápolis', year: 1994 },
  //   { name: 'Belo Horizonte', year: 1994 },
  //   { name: 'Brasília', year: 1994 },
  // ];

  // const handleCityChange = (event, newValue) => {
  //   setIsCity(newValue); // Atualize o estado com o valor selecionado
  // };

  const sendLead = async (e) => {
    e.preventDefault()
    setIsLoading(true)
    const newLead = {
      name: isName,
      email: isEmail,
      telefone: isTelefone,
      cidade: isCity
    }
    const allFields = Object.values(newLead).every(value => value);

    if (!allFields) {
      setIsLoading(false)
      return notifyError()
    }

    axios.post('https://hapvidaplanos.com.br/api_contatos/api_sl_google.php', {
      name: newLead.name,
      email: newLead.email,
      telefone: newLead.telefone,
      cidade: newLead.cidade
    }).then((res) => {
      setIsLoading(false)
      window.open("/sucesso.html", "_blank");
      setIsName('')
      setIsEmail('')
      setIsCity('')
      setIsTelefone('')
      notifySuccess()
    }).catch(err => {
      console.log(err)
      return notifyError()
    })
  }

  return (
    <form id={props.id} onSubmit={sendLead} className='inputContainer'>
      <div className='inputItem'>
        <label htmlFor="">Nome</label>
        <div className="input" style={{ width: '100%' }}>
          <Input type='text' value={isName} onChange={value => setIsName(value)} />
        </div>
      </div>
      <div className="inputItem">
        <label htmlFor="">Email</label>
        <div className="input" style={{ width: '100%' }}>
          <Input type='email' value={isEmail} onChange={value => setIsEmail(value)} />
        </div>
      </div>
      <div className="inputItem">
        <label htmlFor="">Telefone</label>
        <div className="input" style={{ width: '100%' }}>
          <Input telefone type='text' value={isTelefone} onChange={value => setIsTelefone(value)} />
        </div>
      </div>
      <div className="inputItem">
        <label htmlFor="">Cidade</label>
        <div className="input">
          {/* <Stack spacing={2} sx={{ width: '100%' }}>
            <Autocomplete
              freeSolo
              id="citys"
              disableClearable
              onChange={handleCityChange}
              options={citys.map((option) => option.name)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Onde você quer usar seu plano?"
                  value={isCity}
                  onChange={e => setIsCity(e.target.value)}
                  InputProps={{
                    ...params.InputProps,
                    type: 'search',
                  }}
                />
              )}
            />
          </Stack> */}
          <div className="input" style={{ width: '100%' }}>
            <Input type='text' value={isCity} onChange={value => setIsCity(value)} />
          </div>
        </div>

      </div>
      <ButtonMenu submit={sendLead} fontWeight='700' color='#F5821F'>{isLoading ? 'Carregando...' : 'Simular Plano'}</ButtonMenu>
      <ToastContainer />
    </form>
  )
}

export default Form