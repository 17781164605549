import styles from './Slide.module.css'
import { register } from 'swiper/element/bundle'
import ButtonMenu from '../Button'

import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import 'swiper/css/autoplay';
register();

const Slide = (props) => {
  return (
    <Swiper
      slidesPerView={1}
      pagination={{ clickable: true }}
      navigation={true}
      autoplay={true}
      loop={true}
    >
      <SwiperSlide>
        <div className={`${styles.containerSlide} ${styles.bgWhite}`}>
          <div className={styles.contentCenter}>
            <h2 className={`${styles.title} ${styles.blue}`}>Indique e ganhe</h2>
            <h3 className={`${styles.subtitle} ${styles.orange}`}>Compartilhe Saúde com o </h3>
            <h3 className={`${styles.subtitle} ${styles.yellow}`}>Quem Indica Hapvida</h3>
            <p className={`${styles.text} ${styles.blue}`}>Conheça o primeiro programa de bonificação para clientes Hapvida!</p>
            <p className={`${styles.text} ${styles.blue}`}> Indique quantos amigos quiser e a cada nova adesão você ganha um bônus. </p>
            <div className='mt-2 mb-2'>
              <ButtonMenu width="28rem" fontWeight="700" color="#ff5917">Conheça o Quem Indica</ButtonMenu>
            </div>
          </div>
          <div className={styles.contentCenter}>
            <img className='margin-center' src="/images/banner-indicavida.png" alt="banner-indica-vida" />
          </div>
        </div>
      </SwiperSlide>
      <SwiperSlide>
        <div className={`${styles.containerSlide} ${styles.bgWhite} ${styles.flexCenter}`}>
          <h2 className={`${styles.title} ${styles.orange}`}>Milhares de descontos para você aproveitar pra valer.</h2>
          <h3 className={`${styles.subtitle} ${styles.blue}`}> São condições especiais em diversos segmentos de saúde, viagens, alimentação e muito mais! </h3>
          <p className={`${styles.text} ${styles.black}`}> Para usar os descontos não precisa pagar nada, nem acumular pontos, basta ser Cliente do Sistema Hapvida*. </p>
          <div className='mt-2 mb-2'>
            <ButtonMenu width="28rem" fontWeight="700" color='#ff5917'>Conheça o clube de vantagens</ButtonMenu>
          </div>
        </div>
      </SwiperSlide>
      <SwiperSlide>
        <div className={`${styles.containerSlide} ${styles.bgWhite}`}>
          <h2 className={`${styles.title} ${styles.blue}`}>Conheça o MedPrev</h2>
          <h3 className={`${styles.subtitle} ${styles.black}`}> Conte com os nossos melhores programas de medicina preventiva!</h3>
          <p className={`${styles.text} ${styles.black}`}>  Temos vários programas que acompanham a sua saúde, te acolhem e te ajudam a ter hábitos mais saudáveis, todos os dias. </p>
          <div className={`colums-3 gap-1 mt-2 mb-2 ${styles.alignCenter}`}>
            <div className={styles.card}>
              <h3 className={`${styles.top} ${styles.blue}`}>Nascer</h3>
              <h3 className={`${styles.orange}`}>Bem</h3>
            </div>
            <div className={`${styles.card} ${styles.imgCenter}`}>
              <img src="https://contrate-online.hapvida.com.br/assets/images/image9.png" alt="" />
            </div>
            <div className={styles.card}>
              <h3 className={`${styles.top} ${styles.orange}`}>Viver</h3>
              <h3 className={`${styles.orange}`}>Bem</h3>
            </div>
            <div className={styles.card} >
              <h3 className={`${styles.top} ${styles.blue}`}>Viva</h3>
              <h3 className={`${styles.blue}`}>Leve</h3>
            </div>
            <div className={styles.card}>
              <h3 className={`${styles.yellow} ${styles.center}`}>Reviver</h3>
            </div>

          </div>
          <div className='mt-2 mb-2'>
            <ButtonMenu width="28rem" fontWeight="700" color='#ff5917'>Saiba mais</ButtonMenu>
          </div>
        </div>
      </SwiperSlide>
      {/* <SwiperSlide>
        <div className={`${styles.containerSlide} ${styles.bgTimao} ${styles.flexCenter}`}>
          <div className={styles.contentTimao}>
            <h2 className={`${styles.title} ${styles.white}`}> Hapvida Timão, o plano oficial do </h2>
            <h3 className={`${styles.title} ${styles.white}`}>Torcedor</h3>
            <h3 className={`mb-3 ${styles.bigTitle} ${styles.yellow}`}>Corinthiano</h3>
            <h3 className={`${styles.subtitle} ${styles.white}`}>você que é louco pelo Timão, cuide da saúde de sua família com nosso plano personalizado. </h3>
            <div className='mt-2 mb-2 contentButton'>
              <ButtonMenu width="28rem" fontWeight="700" color='#feb900'>Conheça o Hapvida Timão</ButtonMenu>
            </div>
          </div>
          <div className={styles.contentImage}>
            <img src="https://contrate-online.hapvida.com.br/assets/images/logocorinthians.png" alt="" />
          </div>
        </div>
      </SwiperSlide> */}
    </Swiper>
  )
}

export default Slide