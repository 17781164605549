import styles from './Container.module.css'

const Container = ({ id, image, children, white, darkWhite, hAuto, orange, noPadding, lightBlue }) => {
  return (
    <div
      id={id}
      className={`
        ${styles.container} 
        ${white && styles.bgWhite} 
        ${lightBlue && styles.bgLightBlue} 
        ${darkWhite && styles.bgDarkWhite} 
        ${noPadding && styles.noPadding} 
        ${orange && styles.bgOrange} 
        ${hAuto && styles.hAuto}
      `}
      style={image && { backgroundImage: `url(${image})` }}
    >
      {children}
    </div>
  )
}

export default Container