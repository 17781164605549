import styles from './Button.module.css'
import { animateScroll as scroll } from 'react-scroll';

const Button = (props) => {
  const handleClick = (event) => {
    event.preventDefault();
    const headerHeight = document.querySelector('#header').offsetHeight;
    const formElement = document.querySelector('#form');
    const formPosition = formElement.offsetTop;
    scroll.scrollTo(formPosition - headerHeight, { smooth: true });
  }
  return (
    <>
      {props.submit ?
        <button
          className={styles.button}
          style={{ backgroundColor: props.color, fontWeight: props.fontWeight, width: props.width }}
        >
          {props.children}
        </button>
      :
        <button
          className={styles.button}
          style={{ backgroundColor: props.color, fontWeight: props.fontWeight, width: props.width }}
          onClick={handleClick}
        >
          {props.children}
        </button>
      }
    </>
  )
}

export default Button