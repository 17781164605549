import './App.css';
import Container from './Components/Container';
import ButtonMenu from './Components/Button';
import Header from './Components/Header';
import * as React from 'react';
import MiniContainer from './Components/MiniContainer';
import { MdExpandMore } from 'react-icons/md';
import { BsWhatsapp } from 'react-icons/bs';
import Floating from './Components/Floating'

import TimeLine from './Components/TimeLine';
import Footer from './Components/Footer';
import Slide from './Components/Slide';
import Slide2 from './Components/Slide2';
import Form from './Components/Form';

function App() {

  return (
    <main>
      <Header />

      {/* <div style={{padding: "6rem", paddingBottom: "0"}}>
        <h1 className='title'>Cuide da saúde de quem você ama de onde estiver!</h1>
      </div> */}
      {/* <Container id='home' hAuto>
        <p className='subtitle'>Conte com uma rede de cobertura completa, preços que cabem no seu bolso e o melhor: você monta o plano que é ideal para o seu momento.</p>

        <span className="divider"></span>
        <div className="content-form">
          <div className='infos'>
            <h2 className='title'>Planos Hapvida</h2>
            <h2 className='title'>Saiba o Preço aqui!</h2>
            <p className='text-price mt-1'>Planos com preços a partir de</p>
            <h3 className='price'>R$ 84,82*</h3>
            <p className="subtitle mb-3">Entre em contato e ganhe um desconto imperdível no plano Hapvida HOJE!</p>
          </div>
          <div id='formulario' style={{ width: "100%" }}>
            <p className='titleInput'>Simule o seu plano aqui</p>
            <Form id='form' />
            <p className='textUnder mt-1'>Acessar minha localização atual</p>
          </div>
        </div>
      </Container> */}
      <div className="content-form">
        <div className="formulario">
          <div className='infos'>
            <h2 className='title'>Planos Hapvida</h2>
            <h2 className='title'>Saiba o Preço aqui!</h2>
            <p className='text-price mt-1'>Planos com preços a partir de</p>
            <h3 className='price'>R$ 84,82*</h3>
            <p className="subtitle mb-3">Entre em contato e ganhe um desconto imperdível no plano Hapvida HOJE!</p>
            <a className='button-wpp' href="https://wa.me/5571992402722?text=Olá!%20Quero%20mais%20informações,%20por%20favor.%20" target='_blank' rel="noreferrer"><BsWhatsapp />FALE COM UM CONSULTOR AGORA!</a>
          </div>
          <div id='formulario' style={{ width: "85%" }}>
            <p className='titleInput'>Simule o seu plano aqui</p>
            <Form id='form' />
            <p className='textUnder mt-1'>Acessar minha localização atual</p>
          </div>
        </div>
      </div>

      <Container orange hAuto id='diferenciais'>
        <div className='content margin-center p-0 container-diferenciais'>
          <h2 className='title mb-4 title-infos'>Quem tem Hapvida, conta com:</h2>
          <div className="colums-3 gap-3 mb-4">
            <div className='card-info' >
              <figure>
                <img src="https://contrate-online.hapvida.com.br/assets/images/enfermeira-hap.png" alt="" />
              </figure>
              <h4 className="subtitle">Rede Exclusiva</h4>
              <p>Com 43 hospitais próprios, 45 prontos atendimentos, 130 clínicas e 109 centros de diagnóstico por imagem e coleta laboratorial.</p>
            </div>
            <div className='card-info'>
              <figure>
                <img src="https://contrate-online.hapvida.com.br/assets/images/telefone-hap.png" alt="" />
              </figure>
              <h4 className="subtitle">Teleconsulta</h4>
              <p>De onde estiver: você pode fazer suas consultas preventivas ou mesmo ter atendimento básico pela teleconsulta, no seu smartphone, tablet ou computador.</p>
            </div>
            <div className='card-info'>
              <figure>
                <img src="https://contrate-online.hapvida.com.br/assets/images/dental-care-hap.png" alt="" />
              </figure>
              <h4 className="subtitle">Odontologia</h4>
              <p>Só no Hapvida, você tem um
                plano completo de odontologia
                incluso, com prevenção,
                diagnóstico, urgência 24h e
                cobertura em todo Brasil.</p>
            </div>
            <div className='card-info'>
              <figure>
                <img src="https://contrate-online.hapvida.com.br/assets/images/atendimento-hap.png" alt="" />
              </figure>
              <h4 className="subtitle">Atendimento ao cliente 24h</h4>
              <p>A sua saúde não pode esperar e
                por isso, temos uma equipe
                exclusiva para te ajudar com
                marcações de consulta, exames e
                autorizações.</p>
            </div>
            <div className='card-info'>
              <figure>
                <img src="https://contrate-online.hapvida.com.br/assets/images/bebe-hap.png" alt="" />
              </figure>
              <h4 className="subtitle">Rede Pediátrica Exclusiva</h4>
              <p>A maior rede de atendimento
                infantil, com UTI neonatal,
                acompanhamento pediátrico e
                uma infraestrutura moderna.</p>
            </div>
            <div className='card-info'>
              <figure>
                <img src="https://contrate-online.hapvida.com.br/assets/images/chat-icone.png" alt="" />
              </figure>
              <h4 className="subtitle">Chat Hapvida</h4>
              <p>Faça agendamentos, solicite autorizações e tire as suas dúvidas pelo nosso chat.</p>
            </div>
          </div>
          <ButtonMenu color='#0054b8' fontWeight='700'>Simule seu plano!</ButtonMenu>
        </div>
      </Container>

      <Container white hAuto>
        <div className="content p-0 margin-center rede-exclusiva" id='rede-exclusiva'>
          <h3 className="title light-blue">Rede Exclusiva</h3>
          <p className='info mb-4'>O Hapvida conta com uma completa estrutura à sua disposição.</p>

          <div className="colums-4 mt-4">
            <div>
              <h2 className="subtitle light-blue">43</h2>
              <p className='name'>Hospitais Próprios</p>
            </div>
            <div>
              <h2 className="subtitle light-blue">45</h2>
              <p className='name'>Pronto-Atendimentos</p>
            </div>
            <div>
              <h2 className="subtitle light-blue">130</h2>
              <p className='name'>Clínicas</p>
            </div>
            <div>
              <h2 className="subtitle light-blue">109</h2>
              <p className='name'>Centros de Diagnóstico por Imagem e Coleta Laboratorial</p>
            </div>
          </div>
        </div>
      </Container>

      <Container white hAuto>
        <h3 className="title blue margin-center">Cobertura</h3>
        <p className="largeText center mt-0">Aqui, você pode ser atendido nas nossas unidades, em todas as regiões do país em que a rede Hapvida está presente, sem pagar nada a mais por isso.</p>
      </Container>
      <Container white hAuto noPadding>
        <div style={{ width: '100%', height: '100%', display: 'flex', }}>
          <Slide2 />
        </div>
      </Container>

      <Container hAuto lightBlue id='duvidas'>
        <h3 className="title margin-center p-2 title-duvida">Tire suas dúvidas</h3>
        <div className="colums-3 gap-3 mt-4 container-duvida">
          <div className="card-duvida">
            <h4 className='title mb-2'>1. Como fazer para marcar consultas e/ou exames?</h4>
            <p className="text">Você pode marcar sua consulta através do Site, App e via Call Center ou diretamente com o dentista credenciado. Antes de tudo consulte o guia médico em nosso site e veja qual a rede de atendimento disponível para o seu plano. Verifique também as suas carências contratuais. Os procedimentos só podem ser realizados após concluído o tempo de carência.</p>
          </div>
          <div className="card-duvida">
            <h4 className='title mb-2'>2. Como agir no caso de urgência e/ou emergência?</h4>
            <p className="text">Não é necessário marcar atendimento. Basta dirigir-se a uma unidade hospitalar ou de pronto atendimento portando o cartão Hapvida e o documento oficial com foto (RG). A carência para este atendimento é de 24h após a contratação.</p>
          </div>
          <div className="card-duvida">
            <h4 className='title mb-2'>3. Como incluir recém-nascido no plano de saúde?</h4>
            <p className="text">Mães que tiveram parto pelo plano Hapvida poderão incluir seus bebês como dependentes até 30 dias contados do dia do nascimento. Se você está gestante, procure detalhes de como proceder no setor de cadastro do Hapvida (se o seu plano for individual/familiar) ou no RH de sua empresa (se o seu plano for empresarial).</p>
          </div>
          <div className="card-duvida">
            <h4 className='title mb-2'>4. O que fazer quando o exame ou procedimento precisar de autorização?</h4>
            <p className="text">Alguns exames podem ser autorizados através do Chat ou Call Center. Outros devem ser autorizados pessoalmente. Nesse caso, procure um dos postos de autorização do Hapvida com os seguintes documentos: cédula de identificação; cartão beneficiário do Hapvida; exames realizados e relacionados à patologia; guias e/ou relatórios médicos preenchidos.</p>
          </div>
          <div className="card-duvida">
            <h4 className='title mb-2'>5. A partir de quando o meu plano garantirá cobertura para a COVID-19?</h4>
            <p className="text">A partir de 24 horas de vigência do seu contrato, se você necessitar de atendimento de emergência, a Hapvida prestará o atendimento necessário e garantirá o exame que diagnostica a COVID-19, DESDE QUE o médico assistente ateste que você atende a Diretriz de Utilização estabelecida no ROL DA ANS, para este procedimento. O período de carência para qualquer tipo de internação clínica, inclusive por COVID - 19, é de 180 (cento e oitenta) dias. Assim, se o seu atendimento de emergência evoluir para internação clínica, cessar-se-á a obrigação da Operadora, nos termos da CONSU No. 13/98.</p>
          </div>
        </div>
        <div className='mt-4 margin-center'>
          <ButtonMenu color='#F5821F' fontWeight='700'>Simule seu plano!</ButtonMenu>
        </div>
      </Container>

      <Floating whatsapp />
      <Floating />

    </main>
  );
}

export default App;
