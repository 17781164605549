import styles from './Header.module.css'
import * as React from 'react';
import ButtonMenu from '../Button';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { MdExpandMore, MdSupportAgent } from 'react-icons/md'
import { GrClose } from 'react-icons/gr'
import { AiOutlineMenu } from 'react-icons/ai'
import { useState } from 'react';
import { animateScroll as scroll } from 'react-scroll';

const Header = (props) => {

  const [isMobile, setIsMobile] = useState(false)

  const scrollToSection = (sectionId) => {
    if (isMobile) {
      setIsMobile(false)
    }
    const section = document.querySelector(sectionId);
    if (section) {
      const headerHeight = 0; // Convertendo 6.4rem para pixels (16 pixels por rem)
      const offset = section.offsetTop - headerHeight;
      scroll.scrollTo(offset, { smooth: true });
    }
  };

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <header className={styles.header} id='header'>
      <img style={{ cursor: 'pointer' }} onClick={() => scrollToSection('#home')} src="https://contrate-online.hapvida.com.br/assets/images/hapvida-branco-150.png" alt="" />

      <div className={`${styles.menuMobile} ${isMobile && styles.active}`}>
        <div style={{ height: '3.2rem', position: 'relative' }}>
          <button onClick={() => setIsMobile(false)} className={styles.buttonClose}><GrClose /></button>
        </div>
        <ul>
          <li>
            <Button
              id="basic-button"
              aria-controls={open ? 'basic-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={open ? 'true' : undefined}
              onClick={() => scrollToSection('#formulario')}
            >
              Central de vendas
            </Button>
            <Menu
              className={styles.options}
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                'aria-labelledby': 'basic-button',
              }}
            >
              <MenuItem className={styles.option} onClick={handleClose}> <MdSupportAgent size={24} /> 0800 777 9070</MenuItem>
            </Menu>
          </li>
          <li onClick={() => scrollToSection('#diferenciais')}>Vantagens</li>
          <li onClick={() => scrollToSection('#rede-exclusiva')}>Rede Exclusiva</li>
          <li onClick={() => scrollToSection('#duvidas')}>Perguntas Frequentes</li>
          <li> <ButtonMenu>Vizualizar orçamento</ButtonMenu> </li>
        </ul>
      </div >
      <nav>
        <div onClick={() => setIsMobile(!isMobile)} className={styles.mobile}>
          <AiOutlineMenu />
        </div>
        <ul className={styles.desktop}>
          <li>
            <Button
              id="basic-button"
              aria-controls={open ? 'basic-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={open ? 'true' : undefined}
              onClick={() => scrollToSection('#formulario')}
            >
              Simular plano 
            </Button>
            <Menu
              className={styles.options}
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                'aria-labelledby': 'basic-button',
              }}
            >
              <MenuItem className={styles.option} onClick={handleClose}> <MdSupportAgent size={24} /> 0800 777 9070</MenuItem>
            </Menu>
          </li>
          <li onClick={() => scrollToSection('#diferenciais')}>Vantagens</li>
          <li onClick={() => scrollToSection('#rede-exclusiva')}>Rede Exclusiva</li>
          <li onClick={() => scrollToSection('#duvidas')}>Perguntas Frequentes</li>
          <li> <ButtonMenu>Vizualizar orçamento</ButtonMenu> </li>
        </ul>
      </nav >

    </header >
  )
}

export default Header